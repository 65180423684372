<template>
    <div>
        <div v-for="(phase, n) in payPhase" :key="n">
            <div class="mb-1">
                <template v-if="allowMultiplePhase">
                    <span style="color: #000000">【第{{ n + 1 }}期】</span>
                </template>
                <span class="backgauge1">支付日期</span>
                <el-date-picker
                    class="mr-3"
                    style="width: 200px"
                    v-model="phase.paymentDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    @blur="updateFaterComponentData"
                    placeholder="请选择"
                    :editable="false"
                    prefix-icon="el-icon-time"
                >
                </el-date-picker>
                <span class="backgauge1">支付金额</span>
                <input
                    type="number"
                    style="
                        width: 130px;
                        border-radius: 4px;
                        border: 1px solid #979797;
                    "
                    @blur="updateFaterComponentData"
                    v-model="phase.amount"
                    placeholder="1-999999"
                    calss="backgauge1"
                />
                元
                <template v-if="allowMultiplePhase && n > 0">
                    <img
                        src="../assets/delete.png"
                        class="delete-icon"
                        @click="changePayPhase(n)"
                        alt=""
                    />
                </template>
            </div>
            <div class="mb-1" v-if="haveDeduction">
                <label class="backgauge" style="padding-left: 10px;">
                    <input type="checkbox" v-model="phase.deduction" style="vertical-align:-4%" />
                    <span style="color: #000000">抵扣金额</span>
                </label>
                <template v-if="phase.deduction" class="inputwidth">
                    <input
                        type="text"
                        class="custom-input backgauge1"
                        placeholder="1-999999"
                        @blur="updateFaterComponentData"
                        style="width: 140px color:#999999"
                        v-model="phase.deductionAmount"
                    />元
                    <label class="ml-1">
                        <span style="color: #000000">抵扣说明</span>
                        <input
                            type="text"
                            class="custom-input backgauge1"
                            placeholder="限15个字"
                            @blur="updateFaterComponentData"
                            style="width: 240px"
                            v-model="phase.comment"
                        />
                    </label>
                </template>
            </div>
        </div>
        <template v-if="allowMultiplePhase">
            <button class="btn btn-primary" @click="changePayPhase(null)" style="margin-left: 10px;">
                <i class="glyphicon glyphicon-plus"></i>添加支付日期及支付金额
            </button>
        </template>
    </div>
</template>
<script>
export default {
    name: "PayPhaseComponent",
    props: {
        allowMultiplePhase: Boolean,
        data: Array,
        haveDeduction: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            payPhase: [
                {
                    deduction: false,
                    paymentDate: "",
                    amount: "",
                    deductionAmount: "",
                    comment: "",
                },
            ],
        };
    },
    methods: {
        /**
         * 改变合同支付分期
         * @param {Number | Null} deleteIndex 删除的下标  如果不为null就是删除
         * */
        changePayPhase(deleteIndex) {
            if (deleteIndex != null) {
                this.payPhase.splice(deleteIndex, 1);
                this.updateFaterComponentData();
            } else {
                const defaultPayPhase = {
                    deduction: false,
                    paymentDate: "",
                    amount: "",
                    deductionAmount: "",
                    comment: "",
                };
                this.payPhase.push(defaultPayPhase);
            }
        },
        updateFaterComponentData() {
            this.$emit("change-pay-pause", this.payPhase);
        },
        // 初始化--用作数据回显
        initPayPhase() {},
        // 返回组件内数据
        returnPayPhase() {
            return this.payPhase;
        },
    },
    created() {
        this.payPhase = this.data;
    },
};
</script>

<style lang="stylus" scoped>
.delete-icon
    width 23px
    height 23px
    vertical-align middle
.custom-input .backgauge1
    border #999999
    color #000
.col-sm-9
  padding-left 0
.backgauge1
    color #000000
.cs-style .custom-select, .cs-style .custom-input
    width 220px !important
</style>
